import React  from 'react';
import Slider from 'react-slick';
//import styled                                from '@emotion/styled';
//import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import HeaderImage from '../HeaderImage/HeaderImage';

/* eslint-disable-next-line */
import 'slick-carousel/slick/slick.css';
/* eslint-disable-next-line */
import 'slick-carousel/slick/slick-theme.css';
import './style.css';

// const ArrowContainer = styled.button`
//   border: none;
//   outline: none;
//   cursor: pointer;
//   border-radius: ${props => props.next ? '3px 0 0 3px' : '0 3px 3px 0'};
//   width: 50px;
//   height: 50px;
//   background: black;
//   opacity: 0.6;
//   color: #fff;
//   transition: opacity 0.1s;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   &:hover {
//     opacity: 1;
//   }
// `;
//
// // TODO translate aria-labels
// function NextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     //eslint-disable-next-line
//     <div className={className}
//          style={{
//            ...style
//          }}
//          onClick={onClick}>
//       <ArrowContainer next aria-label={'Nächstes Bild'}>
//         <IoIosArrowForward style={{ height: '30px', width: '30px', color: '#fff' }}/>
//       </ArrowContainer>
//     </div>
//   );
// }
//
// function PrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     //eslint-disable-next-line
//     <div className={className}
//          style={{
//            ...style,
//          }}
//          onClick={onClick}>
//       <ArrowContainer aria-label={'Vorheriges Bild'}>
//         <IoIosArrowBack style={{ height: '30px', width: '30px', color: '#fff' }}/>
//       </ArrowContainer>
//     </div>
//   );
// }

const defaultOptions = {
  className: 'int-custom',
  dots: true,
  infinite: true,
  autoplaySpeed: 5000,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: true,
  nextArrow: null,
  prevArrow: null,
  lazyLoad: true,
  draggable: false,
};

// TODO accept array of images as prop to render instead of children / alongside childen
class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    this.slider.slickNext();
  }

  previous() {
    this.slider.slickPrev();
  }

  render() {
    const options = {
      ...defaultOptions,
      ...this.props.options,
    };
    return (
      React.Children.count(this.props.children) === 1
        ? <HeaderImage>{this.props.children}</HeaderImage>
        :
        <div>
          <Slider {...options} ref={c => (this.slider = c)}>
            {this.props.children}
          </Slider>
        </div>
    )
  }
}

export default Carousel;
